import Link from "next/link";
import {ILinkComponent} from "../../../lib/types/common";
import React, {CSSProperties, useEffect, useState} from "react";
import useIsMobile from "../../../lib/customHooks/isMobile";

const CustomLinkComponent = (props: React.PropsWithChildren<ILinkComponent>) => {
    const {isMobileDevice} = useIsMobile()
    const [style, setStyle] = useState<CSSProperties | undefined>(undefined)

    useEffect(() => {
        if (isMobileDevice) {
            setStyle(props.mobStyle)
        } else {
            setStyle(props.desktopStyle)
        }
    }, [isMobileDevice]);

    return (
        <Link
            href={props.href ? props.href : "#"}
            replace={props.replace}
            prefetch={props.prefetch || false}
            style={{textDecoration: "none", ...style}}
            className={props.className}
            scroll={props.scroll}
            passHref={props.passHref}
            id={props.id}
            locale={props.locale}
            key={props.className + "_" + props.href}
            onClick={props.onClick}
            suppressHydrationWarning
        >{props.children}</Link>
    )
}

export default CustomLinkComponent
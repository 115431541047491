import {IImageComponent} from '../../../lib/types/common';
import {nextImageLoader} from '../../../lib/utils/constants/PagesConstants';
import React from "react";
import Image from "next/image";

const CustomImageComponent = (props: IImageComponent) => {
    const {
        layout,
        objectFit,
        src,
        alt,
        priority = undefined,
        height,
        width,
        objectPosition,
        className
    } = props;

    return (
        <>
            {src ?
                <Image
                    loader={nextImageLoader}
                    fill={layout === "fill"}
                    src={src}
                    alt={alt ? alt : ""}
                    priority={priority}
                    height={height}
                    width={width}
                    // objectPosition={objectPosition}
                    // objectFit={objectFit}
                    style={{
                        objectFit: objectFit,
                        objectPosition: objectPosition,
                    }}
                    className={className}
                />
                :
                <></>}
        </>

    )
}

export default CustomImageComponent;